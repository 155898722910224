<script setup lang="ts">
import { nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useFiltersStore } from "@/store/filters";
import { truncateString } from "@/utils/formatters.ts";

const route = useRoute();
const router = useRouter();
const filterStore = useFiltersStore();

const resetValues = () => {
  filterStore.updateProductSearchInStore([]);
  filterStore.updateCurrentSelectedProductInStore(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { product, ...rest } = route.query;

  nextTick(() => {
    router.replace({ path: route.path, query: rest });
  });
};
</script>

<template>
  <div
    v-if="
      (filterStore.productSearch.length > 0 &&
        (route.meta.filters as string[])?.includes('product')) ||
      (filterStore.currentSelectedProduct &&
        (route.meta.filters as string[])?.includes('product'))
    "
    class="flex items-center max-h-[60px] text-[10px] 2xl:text-[12px]"
  >
    <section class="flex flex-col">
      <div class="text-[10px] text-mainText">Product:</div>
      <div class="tip">
        <span
          v-if="
            (filterStore.currentSelectedProduct?.PRODUCT_NAME as string)
              .length >= 30
          "
          v-tooltip="filterStore.currentSelectedProduct?.PRODUCT_NAME"
        >
          {{
            truncateString(
              filterStore.currentSelectedProduct?.PRODUCT_NAME as string,
              30,
            )
          }}
        </span>
        <span v-else>
          {{ filterStore.currentSelectedProduct?.PRODUCT_NAME }}
        </span>

        (SKU:
        <span class="text-primary">
          {{ filterStore.currentSelectedProduct?.SKU }}
        </span>
        / ASIN:
        <span class="text-primary">
          {{ filterStore.currentSelectedProduct?.ASIN }}
        </span>
        )
        <div
          class="cursor-pointer flex justify-center items-center"
          @click="resetValues"
        >
          <feather-icon type="x" size="10px" />
        </div>
      </div>
    </section>
  </div>
</template>
