import { defineStore } from "pinia";
import { getBrandGroups, getBrands, getChannels, productSearch } from "@/api";
import type { TableRow } from "@/types/table";

export interface Brand {
  BRAND_PK: number;
  BK_BRAND_ID: string;
  BRAND_NAME: string;
  BRAND_SHORT_NAME: string;
  IS_ACTIVE: boolean;
}

export interface Channels {
  DIM_CHANNEL_PK: number;
  CHANNEL_ID: number;
  NAME: string;
  PLATFORM: string;
  TLD: string;
  LOCALE: string;
  CURRENCY: string;
  STATUS: string;
}

interface Params {
  brand?: string[];
  search: string;
}

interface Product {
  PRODUCT_ID: number;
  PRODUCT_NAME: string;
  ASIN: string;
  SKU: string;
}

export interface ProductGroup {
  BRAND_GROUPS_ID: number;
  BRAND_GROUPS_SHORT_NAME: string;
  PARENT_BRAND_NAME: string;
  BRANDS: BrandFromProductGroup[];
}

export interface BrandFromProductGroup {
  BRAND_ID: string;
  BRAND_NAME: string;
  BRAND_SHORT_NAME: string;
}

export const useStore = defineStore("main", {
  state: () => ({
    brands: <Brand[]>[],
    channels: <Channels[]>[],
    granularity: <string>"MONTH",
    searchProductResult: <Product[]>[],
    isSearchProductResultLoading: false,
    brandGroups: <ProductGroup[]>[],
    chosenBrandGroups: <any>[],
    periodValues: <any>[],
    isChannelLoading: <boolean>false,
    isBrandLoading: <boolean>false,
    isBrandGroupLoading: <boolean>false,
  }),

  actions: {
    async fetchBrands() {
      if (this.brands.length) return;
      this.isBrandLoading = true;
      try {
        const res = await getBrands();
        if (res.status >= 200 && res.status < 400) {
          this.brands = res.data;
        } else {
          return null;
        }
      } catch (err) {
        console.error(err);
        return;
      } finally {
        this.isBrandLoading = false;
      }
    },
    async fetchChannels() {
      if (this.channels.length) return;
      this.isChannelLoading = true;
      try {
        const res = await getChannels();
        if (res.status >= 200 && res.status < 400) {
          this.channels = res.data;
        } else {
          return null;
        }
      } catch (err) {
        console.error(err);
        return;
      } finally {
        this.isChannelLoading = false;
      }
    },
    async fetchBrandGroups() {
      if (this.brandGroups.length) return;
      this.isBrandGroupLoading = true;
      try {
        const res = await getBrandGroups();
        if (res.status >= 200 && res.status < 400) {
          this.brandGroups = res.data;
        } else {
          return null;
        }
      } catch (err) {
        console.error(err);
        return;
      } finally {
        this.isBrandGroupLoading = false;
      }
    },
    async searchProduct(params: Params) {
      this.isSearchProductResultLoading = true;
      try {
        const res = await productSearch(params);
        if (res.status >= 200 && res.status < 400) {
          this.searchProductResult = res.data.map((el: Product) => ({
            ...el,
            label_title: el.SKU,
            label_name: el.PRODUCT_NAME,
          }));

          return res.data;
        } else {
          return null;
        }
      } catch (err) {
        console.error(err);
        return;
      } finally {
        this.isSearchProductResultLoading = false;
      }
    },

    putGranularity(value: string) {
      this.granularity = value;
    },

    putBrandGroups(value: any) {
      this.chosenBrandGroups = value;
    },
    putPeriodValues(value: string[]) {
      this.periodValues = value;
    },

    clearProductResult() {
      this.searchProductResult = [];
    },

    clearStore() {
      this.brands = [];
      this.channels = [];
      this.brandGroups = [];
    },
  },

  getters: {
    isLoaded(state) {
      return (
        !state.isBrandLoading &&
        !state.isChannelLoading &&
        !state.isBrandGroupLoading
      );
    },

    brandList(state) {
      const sortedOptions = [
        { BRAND_SHORT_NAME: "", BRAND_NAME: "All" },
        ...state.brands,
        ...state.brandGroups,
      ];

      sortedOptions.sort((a: TableRow, b: TableRow) => {
        let nameA, nameB;

        if (a.BRAND_GROUPS_ID && a.BRANDS) {
          nameA = a.PARENT_BRAND_NAME;
          nameB = b.BRAND_NAME;
        } else {
          nameA = a.BRAND_NAME;
          nameB = b.BRAND_NAME;
        }

        if (nameA === "All") return -1;
        if (nameB === "All") return 1;

        if (nameA !== "All" && nameB !== "All") {
          const parentComparison = nameA?.localeCompare(nameB);

          if (parentComparison === 0) {
            const brandComparison = (a.BRAND_NAME || "")?.localeCompare(
              b.BRAND_NAME || "",
            );
            return brandComparison;
          }

          return parentComparison;
        }

        return 0;
      });

      return sortedOptions;
    },
  },
});
