<script lang="ts" setup>
import debounce from "lodash.debounce";
import { ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    label?: string
    border?: boolean
    switchWidth?: string
    // eslint-disable-next-line
      checked: boolean | number | any
  }>(),
  {
    border: false,
    switchWidth: "50px",
  },
);

const emit = defineEmits<{
  // eslint-disable-next-line
  (event: "update:checked", value: any): void
  // eslint-disable-next-line
  (event: "customOnChange", value: any): void
}>();

const internalModelValue = ref(props.checked);

const onChange = debounce(() => {
  emit("customOnChange", internalModelValue.value);
}, 200);

watch(
  () => props.checked,
  (newValue) => {
    internalModelValue.value = newValue;
  },
);
</script>

<template>
  <div>
    <label
      :style="!$slots.label && 'margin: 0 !important'"
      class="input-label"
    >
      <slot name="label" />
      <span v-if="!$slots.label">
        {{ label }}
      </span>
    </label>
    <label class="container">
      <input
        :checked="props.checked as any"
        class="input"
        type="checkbox"
        v-bind="$attrs"
        @change="
          (event: Event) =>
            emit('update:checked', (event.target as HTMLInputElement)?.checked)
        "
        @click="onChange"
      >
      <span
        :class="props.border && 'border-[1px] border-lightSecondary'"
        class="switch"
      >
        <span
          v-if="props.checked"
          class="w-full absolute left-[10px] flex items-center"
        >
          <slot name="icon_active" />
        </span>
        <span
          v-else
          class="absolute right-[10px] flex items-center"
        >
          <slot name="icon_inactive" />
        </span>
      </span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.container {
  @apply cursor-pointer flex items-center;
}

.label {
  @apply ml-[12px] text-secondaryText text-[14px] font-medium;
}

.input {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.switch {
  --switch-container-width: v-bind(switchWidth);
  --switch-size: calc(var(--switch-container-width) / 2);
}

.switch {
  --switch-container-width: v-bind(switchWidth);
  --switch-size: calc(var(--switch-container-width) / 2);
  display: flex;
  align-items: center;
  position: relative;
  height: var(--switch-size);
  flex-basis: var(--switch-container-width);
  border-radius: var(--switch-size);
  background-color: #e2e8f0;
}

.switch::before {
  content: "";
  left: 1px;
  height: calc(var(--switch-size) - 4px);
  width: calc(var(--switch-size) - 4px);
  border-radius: 9999px;
  background-color: white;
}

.input:checked + .switch {
  background-color: #087cf7;
}

.input:checked + .switch::before {
  border-color: #087cf7;
  transform: translateX(
          calc(var(--switch-container-width) - var(--switch-size))
  );
}

.switch {
  transition: background-color 0.25s ease-in-out;
}

.switch::before {
  transform: translateX(calc(var(--switch-size) - 100%));
  transition: transform 0.375s ease-in-out;
}

.switch {
  --light-gray: #e2e8f0;
  --gray: #cbd5e0;
  --dark-gray: #a0aec0;
  --main: #087cf7;
  --dark-teal: #319795;
  @apply bg-inputBg shadow-md dark:shadow-slate-900/50;
}

.input:checked + .switch {
  background-color: var(--main);
}

.input:checked + .switch::before {
  border-color: var(--main);
}

.switch::before {
  border: 2px solid var(--light-gray);
}

.input:focus + .switch::before {
  border-color: var(--dark-gray);
}

.input:focus:checked + .switch::before {
  border-color: var(--dark-teal);
}

.input:disabled + .switch {
  background-color: var(--gray);
}

.input:disabled + .switch::before {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
}

.switch {
  /* In case the label gets long, the toggle shouldn't shrink. */
  flex-shrink: 0;
  border-radius: var(--switch-size);

  @apply border-lightSecondary;
}

.label {
  /* Show an ellipsis if the text takes more than one line */
  overflow: hidden;
  white-space: nowrap;
}
</style>
