<script lang="ts" setup>
import { useColorMode } from "@vueuse/core";
import { ref } from "vue";

const colorMode = useColorMode();

import NewCommonSwitch from "@/components/common/NewCommonSwitch.vue";

const customColor = ref(colorMode.value === "light");
const changeColorMode = (isLightThemeChecked: boolean) => {
  customColor.value = isLightThemeChecked;
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  isLightThemeChecked
    ? (colorMode.value = "light")
    : (colorMode.value = "dark");
};
</script>

<template>
  <section
    :class="customColor"
    class="w-full theme-wrapper"
  >
    <new-common-switch
      v-model:checked="customColor"
      :border="true"
      class="flex justify-center items-center"
      switch-width="70px"
      @custom-on-change="(event) => changeColorMode(event)"
    >
      <template #icon_active>
        <feather-icon
          class="text-white"
          size="17"
          type="sun"
        />
      </template>
      <template #icon_inactive>
        <feather-icon
          size="17"
          type="moon"
        />
      </template>
    </new-common-switch>
  </section>
</template>

<style lang="scss" scoped></style>
