<script setup lang="ts">
import xor from "lodash.xor";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";

import { useStore } from "@/store/main";
import { useFiltersStore } from "@/store/filters";
import type { FilterChannelOption } from "@/types/filters";
import { useAbortController } from "@/store/abortController";

import CommonMultiSelect from "@/components/common/CommonMultiSelect.vue";

type Props = {
  options: Array<FilterChannelOption>;
  defaultValue: string;
  valueReset?: {};
};

const route = useRoute();
const store = useStore();
const router = useRouter();
const filterStore = useFiltersStore();
const abortController = useAbortController();

const props = defineProps<Props>();
const emit = defineEmits(["valueUpdate"]);

const chooseValues = ref<FilterChannelOption[]>([]);
const arrayValues = ref<number[]>([]);

const defineValues = () => {
  arrayValues.value = [];

  chooseValues.value.forEach((el: FilterChannelOption) => {
    if (el.CHANNEL_ID) {
      arrayValues.value.push(el["CHANNEL_ID"]);
    }
  });
  const difference = xor(arrayValues.value, filterStore.channels as number[]);

  if (difference.length) {
    abortController.abortAllRequests();
    emit("valueUpdate", arrayValues.value);
  }
};

const onSelect = (value: FilterChannelOption) => {
  if (value.NAME.toLowerCase() === "all") {
    chooseValues.value = [value];
  } else {
    chooseValues.value.find((el: any) => el.NAME.toLowerCase() === "all") &&
      chooseValues.value.splice(0, 1);
  }
};

const checkIfChannelSelected = (data: FilterChannelOption) => {
  return !!chooseValues.value.find((item) => item.NAME === data.NAME);
};

const optionsList = computed<FilterChannelOption[]>(() => {
  return props.options;
});

const selectedTags = ({ values }: { values: FilterChannelOption[] }) => {
  return values.map((el) => el.NAME);
};

watch(
  () => filterStore.channels,
  (value: Record<string, any>) => {
    chooseValues.value = [];

    const channelToString = filterStore.channels.join(",");

    if (channelToString.length) {
      router.push({ query: { ...route.query, channels: channelToString } });
    }

    if (value.length >= 1) {
      value.forEach((value: number) => {
        const foundOption = store.channels.filter((el) => {
          return el["CHANNEL_ID"] === value;
        });
        chooseValues.value.push(foundOption[0] || optionsList.value[0]);
      });
    }

    if (!value.length) {
      chooseValues.value.push(optionsList.value[0] || optionsList.value[0]);
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (route.query.channels) return;
  chooseValues.value = [optionsList.value[0]];
});
</script>

<template>
  <div class="flex flex-col min-w-[300px]">
    <div v-if="!!props.options.length">
      <CommonMultiSelect
        v-model="chooseValues"
        :options="optionsList"
        label="NAME"
        :show-labels="false"
        @select="onSelect"
        @close="defineValues"
        :multiple="true"
        :clear-on-select="false"
        :close-on-select="false"
        track-by="NAME"
        placeholder=""
        :allowEmpty="false"
        :searchable="false"
      >
        <template v-slot:selection="selection">
          <div class="flex w-full align-center min-h-[26px]">
            <div
              v-for="(item, index) in selectedTags(selection)"
              :key="index"
              class="flex justify-center items-center mr-1 py-1 px-2 rounded-full text-mainText text-[14px] font-normal border-[1px] border-gray-200 dark:border-secondaryText"
              :class="{ 'border-none hidden': index >= 2 }"
            >
              <div
                class="leading-none max-w-full flex-initial cursor-pointer text-mainText"
              >
                {{ item }}
              </div>
            </div>
            <div
              v-if="selectedTags(selection).length > 2"
              class="flex items-center ml-auto text-mainText"
            >
              +{{ selectedTags(selection).length - 2 }} More
            </div>
          </div>
        </template>
        <template v-slot:option="data">
          <div
            class="flex w-full justify-between items-center text-[14px] font-medium"
          >
            {{ data.option.NAME }}
          </div>
        </template>
      </CommonMultiSelect>
    </div>
  </div>
</template>
