<script setup lang="ts">
import Multiselect from "vue-multiselect";
import { ref, useSlots, onMounted, watch, Slots } from "vue";

const $slots: Slots = useSlots();

const test = ref<Multiselect | null | any>(null);

type MultiselectOption = {
  textContent: string;
  style: {
    display: string;
  };
};

const hideOptions = (): void => {
  const options: NodeListOf<any> = test.value?.$el.querySelectorAll(
    ".multiselect__element",
  );
  options.forEach((option: MultiselectOption) => {
    if (option.textContent.includes("All without groups")) {
      option.style.display = "none";
    }
  });
};

onMounted(() => {
  hideOptions();
});

watch(
  () => test.value?.options,
  () => {
    hideOptions();
  },
);

defineSlots<{
  [key: string]: any;
}>();
</script>

<template>
  <div>
    <multiselect v-bind="$attrs" :showLabels="false" ref="test">
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps"></slot>
      </template>
    </multiselect>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.multiselect {
  .multiselect__tags {
    @apply bg-inputBg border-none shadow-md dark:shadow-slate-900/50 text-secondaryText;
  }
  .multiselect__single {
    @apply bg-inputBg text-mainText text-[14px] font-normal;
  }

  .multiselect__option {
    @apply text-mainText font-normal;
  }
  .multiselect__option--selected {
    @apply bg-primary text-white h-fit;
  }

  .multiselect__content-wrapper {
    @apply bg-inputBg text-secondaryText border-none shadow-md dark:shadow-slate-900/50;
  }

  .multiselect__content {
    @apply bg-inputBg text-secondaryText border-none;
  }

  .multiselect__spinner {
    @apply bg-inputBg text-secondaryText;
  }

  input {
    @apply bg-inputBg text-secondaryText;
  }

  .multiselect__tag {
    @apply text-white bg-primary h-fit;
  }
  .multiselect__option--highlight {
    @apply text-mainText bg-hover h-fit;
  }

  .multiselect__element {
    @apply h-fit;
  }

  .multiselect__option--group {
    @apply bg-oddRow text-primary;
  }

  .multiselect__placeholder {
    @apply text-mainText text-[14px] font-normal;
  }
}
</style>
