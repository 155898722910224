<script lang="ts" setup>
import { useRouter } from "vue-router";

import { useToast } from "@/composables/useToast";
import { AuthUserType, useAuthStore } from "@/store/auth/index";

import CommonButton from "@/components/common/CommonButton.vue";
import ThemeSwitcher from "@/components/common/ThemeSwitcher.vue";

const router = useRouter();
const { danger } = useToast();
const authStore = useAuthStore();
const { logout } = useAuthStore();

const props = defineProps<{
  user: AuthUserType
}>();

const logoutUser = async function () {
  await logout();
  if (authStore.token.length === 0) {
    await router.push("/login");
  }
  else {
    danger("Something got wrong. Try again");
  }
};

const changePasswordRedirect = () => {
  router.push("/password-change");
};
</script>

<template>
  <section class="flex flex-col gap-3">
    <div class="flex flex-col gap-3">
      <div class="flex items-center gap-2">
        <span class="flex items-center w-[25px]">
          <feather-icon
            class="text-secondaryText"
            size="20"
            type="mail"
          />
        </span>
        <span
          v-if="props.user?.email"
          class="text-secondaryText text-[12px] font-light"
        >
          {{ props.user?.email }}
        </span>
      </div>
    </div>
    <hr>
    <ThemeSwitcher />
    <hr>
    <common-button
      class="primary-button"
      text="Change Password"
      @click="changePasswordRedirect"
    />
    <common-button
      class="negative-button "
      text="Logout"
      @click="logoutUser"
    >
      <template #iconLeft>
        <feather-icon
          size="20"
          type="log-out"
        />
      </template>
    </common-button>
  </section>
</template>
