<script lang="ts" setup>
import { useRoute } from "vue-router";

import { useAuthStore } from "@/store/auth/index";

import UserMenu from "@/components/core/AppHeader/UserMenu.vue";
import CommonSimpleMenu from "@/components/common/CommonSimpleMenu.vue";
import CommonLettersAvatar from "@/components/common/CommonLettersAvatar.vue";

const route = useRoute();
const authStore = useAuthStore();
</script>

<template>
  <header
    class="
    h-[100px] flex justify-center
    items-center p-4 transition-all duration-500
    "
  >
    <section class="app-header">
      <div class="flex items-center justify-center p-3 gap-4">
        <slot name="left" />
        <h2 class="font-medium text-[24px]">
          {{ route.meta.title }}
        </h2>
      </div>

      <section class="flex gap-3 px-5">
        <slot />
        <div class="flex w-full gap-2">
          <div
            class="
            flex flex-col justify-center
            gap-2 w-full text-right whitespace-nowrap
            "
          >
            <span class="text-mainText text-[14px]">
              {{ authStore.user?.name }}</span>
            <span
              class="text-secondaryText font-extralight text-[14px] capitalize"
            >
              {{ authStore.user?.role }}</span>
          </div>
          <CommonSimpleMenu>
            <template #button>
              <CommonLettersAvatar
                :name="authStore.user?.name || ''"
                online-dot
              />
            </template>
            <template #items>
              <UserMenu :user="authStore.user" />
            </template>
          </CommonSimpleMenu>
        </div>
      </section>
    </section>
  </header>
</template>

<style lang="scss" scoped>
.app-header {
  @apply flex justify-between shadow-md
  dark:shadow-slate-900/50 w-full rounded-lg h-[70px];
  .app-header-logo {
    @apply hidden md:flex justify-center items-center;
  }
}
</style>
