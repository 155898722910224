import { createRouter, createWebHistory } from "vue-router";

import modules from "./modules.ts";
import { useAuthStore } from "@/store/auth";
import { handleNavigation } from "@/utils/routing.ts";
import { useAbortController } from "@/store/abortController";

const router = createRouter({
  history: createWebHistory(),
  routes: modules,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const abortController = useAbortController();

  // Abort all requests when navigating away from the page
  if (from.path !== to.path && from.path !== "/") {
    abortController.abortAllRequests();
  }

  if (
    to.path !== "/login" &&
    to.path !== "/password-forgot" &&
    to.path !== "/reset-password"
  ) {
    await authStore.getUser();
  } else return next();

  handleNavigation(from, to, next, authStore);
});

export default router;
