<script setup lang="ts">
import debounce from "lodash.debounce";
import { ref, watch } from "vue";

const props = defineProps<{
  label?: string;
  checked: boolean | any;
}>();

const emit = defineEmits<{
  (event: "update:checked", value: boolean): void;
  (event: "customOnChange", value: boolean): void;
}>();

const internalModelValue = ref(props.checked);

const onChange = debounce(() => {
  emit("customOnChange", internalModelValue.value);
}, 200);

watch(
  () => props.checked,
  (newValue) => {
    internalModelValue.value = newValue;
  },
);
</script>

<template>
  <label class="container">
    <input
      class="input"
      v-bind="$attrs"
      type="checkbox"
      :checked="props.checked"
      @change="
        (event: Event) =>
          emit('update:checked', (event.target as HTMLInputElement)?.checked)
      "
      @click="onChange"
    />
    <span class="switch"></span>
    <article class="label">
      <slot name="label" />
      <span v-if="!$slots.label">
        {{ label }}
      </span>
    </article>
  </label>
</template>

<style scoped>
.container {
  @apply cursor-pointer flex items-center;
}

.label {
  @apply ml-[12px] text-secondaryText text-[14px] font-medium;
}

.input {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.switch {
  --switch-container-width: 50px;
  --switch-size: calc(var(--switch-container-width) / 2);
}

.switch {
  --switch-container-width: 50px;
  --switch-size: calc(var(--switch-container-width) / 2);
  display: flex;
  align-items: center;
  position: relative;
  height: var(--switch-size);
  flex-basis: var(--switch-container-width);
  border-radius: var(--switch-size);
  background-color: #e2e8f0;
}

.switch::before {
  content: "";
  left: 1px;
  height: calc(var(--switch-size) - 4px);
  width: calc(var(--switch-size) - 4px);
  border-radius: 9999px;
  background-color: white;
}

.input:checked + .switch {
  background-color: #087cf7;
}

.input:checked + .switch::before {
  border-color: #087cf7;
  transform: translateX(
    calc(var(--switch-container-width) - var(--switch-size))
  );
}

.switch {
  transition: background-color 0.25s ease-in-out;
}

.switch::before {
  transition: transform 0.375s ease-in-out;
}

.switch {
  --light-gray: #e2e8f0;
  --gray: #cbd5e0;
  --dark-gray: #a0aec0;
  --main: #087cf7;
  --dark-teal: #319795;
  @apply bg-inputBg shadow-md dark:shadow-slate-900/50;
}

.input:checked + .switch {
  background-color: var(--main);
}

.input:checked + .switch::before {
  border-color: var(--main);
}

.switch::before {
  border: 2px solid var(--light-gray);
}

.input:focus + .switch::before {
  border-color: var(--dark-gray);
}

.input:focus:checked + .switch::before {
  border-color: var(--dark-teal);
}

.input:disabled + .switch {
  background-color: var(--gray);
}

.input:disabled + .switch::before {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
}

.switch {
  /* In case the label gets long, the toggle shouldn't shrink. */
  flex-shrink: 0;
}

.label {
  /* Show an ellipsis if the text takes more than one line */
  overflow: hidden;
  white-space: nowrap;
}
</style>
