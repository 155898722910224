<script setup lang="ts">
import { Slots, useSlots } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";

const $slots: Slots = useSlots();
defineSlots<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}>();

</script>

<template>
  <div>
    <!--    :auto-position="false" position="center"-->
    <VueDatePicker v-bind="$attrs">
      <template
        v-for="(_, slotName) in $slots"
        #[slotName]="slotProps"
      >
        <slot
          :name="slotName"
          v-bind="slotProps"
        />
      </template>
    </VueDatePicker>
  </div>
</template>

<style src="@vuepic/vue-datepicker/dist/main.css"></style>
<style lang="scss">
.dp__main .dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #087cf7;
  --dp-primary-color: #087cf7;
}

.dp__main {
  @apply shadow-md dark:shadow-slate-900/50 text-[12px];

  .dp--preset-dates {
    @apply border-none;
    .dp--preset-range {
      @apply text-secondaryText font-normal text-[14px];
    }
  }

  .dp__selection_preview {
    @apply text-secondaryText font-normal text-[14px];
  }
}

.dp__clear_icon {
  @apply hidden;
}

.dp__range_between {
  @apply bg-hover border-none;
}

.dp__preset_ranges,
.dp__instance_calendar {
  border: none !important;
}

.dp__input {
  @apply text-[14px] font-normal border-none;
}

.dp__input_icon {
  @apply fill-mainText;
}

.dark {
  .dp__main .dp__theme_light {
    --dp-background-color: #222c31;
    --dp-text-color: #e4e7eb;
    --dp-primary-color: #087cf7;
  }

  .dp__input {
    background-color: #222c31;
    color: #cbd2de;
    border: none;
    @apply text-[14px] font-normal;
  }
}
</style>
