<script setup lang="ts">
import { computed } from "vue";

import EmptyData from "@/assets/images/EmptyData.vue";
import BaseLoader from "@/components/common/CommonLoader.vue";
// eslint-disable-next-line style/max-len
import BarChartSkeleton from "@/components/SkeletonPresets/BarChartSkeleton.vue";

const props = withDefaults(
  defineProps<{
    title?: string
    description?: string
    showShadow?: boolean
    emptyData?: boolean
    loading?: boolean
    noDataFoundText?: string
    chart?: boolean
    withoutPadding?: boolean
    optionsForChartSkeleton?: number
    hfull?: boolean
    scale?: boolean
  }>(),
  {
    showShadow: true,
    noDataFoundText: "Unfortunately, there are no data available.",
    optionsForChartSkeleton: 3,
    scale: false,
  },
);

const isHaveSupportText = computed(() => props.title || props.description);
</script>

<template>
  <div
    class="card"
    :class="{
      'shadow-lg dark:shadow-slate-900/50': props.showShadow,
      'p-4 md:p-30': !props.withoutPadding,
      'h-full': props.hfull,
      'h-fit': !props.hfull,
      'hover:scale-[1.01]': props.scale,
    }"
  >
    <div v-if="isHaveSupportText">
      <div
        v-if="title"
        class="font-normal text-mainText text-[16px] flex gap-1 items-center"
      >
        <div>{{ title }}</div>
      </div>
      <div
        v-if="description"
        class="font-light text-[0.7rem] pb-2 text-secondaryText"
      >
        {{ description }}
      </div>
    </div>
    <div
      v-if="props.loading"
      :class="{
        'flex justify-center items-center h-full': !$slots.loading,
      }"
      class="h-full w-full"
    >
      <BaseLoader v-if="!$slots.loading && !props.chart" />
      <div v-if="props.chart">
        <BarChartSkeleton />
      </div>
      <slot name="loading" />
    </div>
    <div
      v-if="!props.emptyData && !props.loading"
      class="text-mainText"
    >
      <slot />
    </div>
    <div
      v-if="!props.loading && props.emptyData"
      class="flex flex-col justify-center items-center h-full"
    >
      <EmptyData />
      <span class="text-[16px] text-mainText"> {{ noDataFoundText }} </span>
    </div>
  </div>
</template>
