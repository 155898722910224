import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import VueFeather from "vue-feather";
import Toast from "vue-toastification";
import FloatingVue from "floating-vue";

import router from "./router";

import App from "./App.vue";

import "./style.scss";
import "floating-vue/dist/style.css";
import "vue-toastification/dist/index.css";

const pinia = createPinia();
const app = createApp(App);

const isSentryEnabled =
  // @ts-expect-error
  import.meta.env.VITE_SENTRY_ENABLED?.toUpperCase() === "TRUE";
// @ts-expect-error
const sentryDSN = import.meta.env.VITE_SENTRY_DSN;

if (isSentryEnabled) {
  Sentry.init({
    app,
    dsn: sentryDSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.3,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.3,
  });
}

app
  .use(router)
  .use(pinia)
  .use(Toast, {
    maxToasts: 5,
  })
  .use(FloatingVue)
  .component("feather-icon", VueFeather)
  .mount("#app");
