<script setup lang="ts">
import DOMPurify from "dompurify";
import { defineEmits, ref, computed } from "vue";

import { BaseValidation } from "@vuelidate/core";

type Props = {
  name?: string;
  modelValue?: string | Date | number;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  supportText?: string;
  validation?: BaseValidation;
  customHighlight?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  type: "text",
});
/**
 * Variable for input type - password
 */
const isShowPassword = ref(false);
/**
 * computed for change input type if we have default type - password
 * if we have default type - any without password we return default type
 */
const currentInputType = computed(() => {
  if (props.type === "password") {
    return isShowPassword.value ? "text" : "password";
  }
  return props.type;
});
/**
 * Function for change input type if we have default type - password
 */
const changeInputType = () => {
  isShowPassword.value = !isShowPassword.value;
};
const emit = defineEmits<{
  (event: "update:modelValue", value: string): void;
  (event: "custonOnChange", data: Event): void;
}>();
function handleInput(e: Event) {
  const value = (e.target as HTMLInputElement).value;
  let sanitizedValue = DOMPurify.sanitize(value).trim();
  const sqlKeywords = /(SELECT|INSERT|DELETE|UPDATE|DROP|UNION|--|\*|;)/gi;
  if (sqlKeywords.test(sanitizedValue)) {
    sanitizedValue = "";
  }
  emit("update:modelValue", sanitizedValue);
}
</script>

<template>
  <div class="form-input-component">
    <label class="label font-regular text-[12px]">{{ name }}</label>
    <div class="w-full flex relative flex-col">
      <span
        class="absolute left-[5%] h-full flex justify-center items-center"
        :class="{
          'w-0': !$slots['icon-left'],
          'w-[25px]': $slots['icon-left'],
        }"
      >
        <slot name="icon-left" />
      </span>
      <div class="input-wrapper">
        <input
          :type="currentInputType"
          class="form-input input-style"
          :disabled="disabled"
          :value="modelValue"
          @input="handleInput"
          :placeholder="placeholder"
          v-bind="$attrs"
          :class="{
            'left-slot': $slots['icon-left'],
            'right-slot': $slots['icon-right'] || props.type === 'password',
            'input-errorBorder': validation?.$error || props.customHighlight,
            'cursor-pointer': type === 'date',
          }"
          @change="(event) => emit('custonOnChange', event)"
        />
        <span
          class="absolute right-[5%] w-[25px] flex justify-center items-center top-[30%]"
        >
          <slot name="icon-right" />
          <div v-if="props.type === 'password'" class="cursor-pointer">
            <span
              v-if="currentInputType === 'text'"
              @click="changeInputType"
              class="w-[20px] stroke-svgColor"
            >
              <feather-icon
                class="ml-1 cursor-pointer password-icon"
                type="eye-off"
                size="16"
              />
            </span>
            <span
              v-else
              @click="changeInputType"
              class="w-[20px] stroke-svgColor"
            >
              <feather-icon
                class="ml-1 cursor-pointer password-icon"
                type="eye"
                size="16"
              />
            </span>
          </div>
        </span>

        <span
          v-if="currentInputType === 'file' && !$slots['icon-right']"
          class="absolute right-[5%] top-[25%] flex justify-center items-center"
        >
          <span class="w-[20px] cursor-pointer"> PaperClipIcon </span>
        </span>
      </div>

      <p
        v-if="supportText"
        class="absolute text-[10px] lg:bottom-[-20px] xl:bottom-[-20px] md:bottom-[-35px] sm:bottom-[-35px] left-[5px]"
      >
        {{ supportText }}
      </p>

      <span
        class="input-errorMessage"
        :class="{ 'input-showMessage': validation?.$error }"
      >
        <p class="font-regular" v-if="validation?.$error">
          {{ validation?.$errors[0]?.$message }}
        </p>
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.input-errorBorder {
  transition: all 0.1s;
  @apply border-2 border-red-500;
}

.input-errorMessage {
  @apply opacity-0 z-[1] invisible text-red-500 text-[0.8rem] pt-1;
  transform: translateY(0px);
  transition:
    transform 0.8s ease,
    opacity 0.4s ease;
}

.input-showMessage {
  @apply opacity-100 visible;
  transform: translateY(5px);
}
</style>
